'use strict';

/**
 * @ngdoc service
 * @name 360StoreAngularApp.analytics
 * @description
 * # analytics
 * Service in the 360StoreAngularApp.
 */
angular.module('360StoreAngularApp')
	.service('Analytics', function () {

		// FIX! duplicate code in shopping-cart.js
		function extendedPrice(item) {
			return item ? item.unitPrice * item.qty : 0;
		}

		const lineItemNamePattern = /^(?<productLine>[^/]+)\s+(?<versionNumber>[^/]+)\s*\/\s*(?<variantTypeName>.+)$/;

		const itemsForActions = (/**CartAction[]*/ cartActions,/**PromoCode*/promoCode) => {
			// let couponCode = promoCode?.name;
			let discountPercent = promoCode?.creditPercent || 0;
			let discountAmount = promoCode?.creditAmount || 0;
			return cartActions.flatMap((action, index) => action.lineItems.map(item => {
						const {productLine, versionNumber, variantTypeName} = (item.description.match(lineItemNamePattern)?.groups || {});
						const trueDiscountAmount = discountAmount || item.unitPrice * (discountPercent/100);
						const discountPrice = item.unitPrice - trueDiscountAmount;
						return {
							item_id: action.variantId,
							item_name: item.description,
							index: index,
							item_brand: productLine,
							item_category: versionNumber,
							item_variant: variantTypeName,
							price: discountPrice,
							discount: trueDiscountAmount,
							quantity: item.qty
						};
					}
				)
			);
		};

		return {

			track_page_view: () => {
				gtag('event', 'page_view', {
					page_title: '360Store ' + window.location.pathname,
					page_location: location || window.location.href,
				});
			},

			// /**
			//  * When a user is presented with a list of results, send a view_item_list event including an items array parameter containing the displayed items. For details on the parameters to send, see the Events reference.
			//  */
			// select_from_list: () => {
			// 	gtag('event', 'view_item_list', {
			// 		item_list_id: 'related_products',
			// 		item_list_name: 'Related products',
			// 		items: [
			// 			{
			// 				item_id: 'SKU_12345',
			// 				item_name: 'Stan and Friends Tee',
			// 				affiliation: 'Google Merchandise Store',
			// 				coupon: 'SUMMER_FUN',
			// 				discount: 2.22,
			// 				index: 0,
			// 				item_brand: 'Google',
			// 				item_list_id: 'related_products',
			// 				item_list_name: 'Related Products',
			// 				item_variant: 'green',
			// 				location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
			// 				price: 10.01,
			// 				quantity: 3
			// 			}
			// 		]
			// 	});
			// },

			/**
			 * To measure how many times item details are viewed, send a view_item event whenever a user views an item’s details screen. For details on the parameters to send, see the Events reference.
			 */
			view_item_details: (/**ProductLine*/ productLine, /**ProductVariant*/variant,/**number*/ index) => {
				gtag('event', 'view_item', {
					// currency: 'USD',
					// value: 30.03,
					items: [
						{
							item_id: variant.id,
							item_name: variant.name,
							// affiliation: 'Google Merchandise Store',
							// coupon: 'SUMMER_FUN',
							// discount: 2.22,
							index: index,
							item_brand: productLine.name,
							item_list_id: 'variant_select',
							item_list_name: 'Variant Select',
							item_variant: variant.variantType?.code,
						}
					]
				});

			},

			/**
			 * This event signifies that an item was added to a cart for purchase.
			 */
			add_to_cart: function (/**ProductLine*/ productLine, /**ProductVersion*/ version, /**ProductVariant*/variant) {
				gtag('event', 'add_to_cart', {
					currency: 'USD',
					value: variant.unitPrice,
					'items': [{
						'item_id': variant.id,
						'item_name': variant.name,
						'item_category': version.name,
						'item_brand': productLine.name,
						'item_variant': variant.variantType.name,
						'price': variant.unitPrice,
						'quantity': 1,
						// 'coupon': coupon,
						// 'index': index,
						// 'custom_properties': custom
					}]
				});
			},

			/**
			 * This event signifies that an item was removed from a cart.
			 * @param cartAction {CartAction}
			 */
			remove_from_cart: function (cartAction) {
				gtag('event', 'remove_from_cart', {
					currency: 'USD',
					value: cartAction.lineItems.reduce((sum, item) => sum + (item.unitPrice || 0), 0),
					items: cartAction.lineItems.map(item => ({
						item_id: cartAction.variantId,
						item_name: item.description,
						price: item.unitPrice,
						quantity: item.qty
					}))
				});
			},

			/**
			 * This event signifies that a user has begun a checkout.
			 * @param {CartAction[]} cartActions
			 */
			begin_checkout: function (cartActions) {
				// FIX! duplicate code in shopping-cart.js
				const value = cartActions.reduce(function (sum, action) {
					return sum + action.lineItems.reduce(function (sum2, item) {
						return sum2 + extendedPrice(item);
					}, 0);
				}, 0);

				gtag('event', 'begin_checkout', {
					currency: 'USD',
					value: value,
					items: itemsForActions(cartActions),
				});
			},
			/**
			 * This event signifies when one or more items is purchased by a user.
			 * @param {ShoppingCart} cart
			 * @param {CartAction[]} cartActions
			 * @param {CheckoutResponse} checkoutResponse
			 */
			purchase: function (cart, cartActions, checkoutResponse) {
				/**
				 * @type {number}
				 */
				let creditPercent = cart?.promoCode?.creditPercent || 0;
				let creditAmount = cart?.promoCode?.creditAmount || 0;
				//it may be rare, but we could have a discountAmount equal to the total value of the cart, in which case we also want to skip sending the purchase event to Google Analytics. --wgs
				if (creditPercent === 100 && cart?.promoCode?.discountOnReport || ( creditAmount > 0 && checkoutResponse.extendedPriceStored === 0 ) ) {
					console.log('NOT sending purchase to Google Analytics', cart.promoCode);
					return;
				}
				gtag('event', 'purchase', {
					transaction_id: checkoutResponse.cartId,
					// Sum of (price * quantity) for all items.
					value: checkoutResponse.extendedPriceStored,
					// tax: 3.60,
					// shipping: 5.99,
					currency: 'USD',
					coupon: cart.promoCode?.name,
					items: itemsForActions(cartActions, cart.promoCode),
				});
			},

			/**
			 * Track some arbitrary event
			 * @param category
			 * @param action
			 * @param label
			 * @param value
			 * @param nonInteraction
			 * @param custom
			 */
			trackEvent: function (category, action, label, value, nonInteraction, custom) {
				gtag('event', action, {
					'event_category': category,
					'event_label': label,
					'value': value,
					'non_interaction': nonInteraction,
					'custom_properties': custom
				});
			},

			/**
			 * Used to indicate that someone opted-in to notifications
			 * @param {string} groupId one of "autoRenew" or "receiveUpdates"
			 */
			join_group(groupId) {
				gtag('event', 'join_group', {
					group_id: groupId
				});
			},

			/**
			 * This event signifies a promotion was selected from a list.
			 * @param promotionName
			 * @param promoCode
			 */
			select_promotion: function (promotionName, promoCode) {
				gtag('event', 'select_promotion', {
					promotion_id: promoCode,
					promotion_name: promotionName,
				});
			},

		};
	});
