'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:UpdatePaymentInfoCtrl
 * @description
 * # UpdatePaymentInfoCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('UpdatePaymentInfoCtrl', function ($location, $scope, api, COUNTRIES, paymentInfoPayload) {
		$scope.countries = COUNTRIES;

		// $scope.countryOptions = dynamicData.countryOptions;
		// $scope.yearOptions = dynamicData.yearOptions;

		/**
		 * @type {PaymentUpdatePayload}
		 */
		$scope.payment = paymentInfoPayload;

		$scope.cardTypeOptions = ['Visa', 'MasterCard', 'American Express', 'Discover'];


		$scope.submitPaymentInfo = async () => {
			$scope.myForm.$$setSubmitted();
			if ($scope.myForm.$invalid) {
				return toastr.error('Please correct invalid form data first');
			}
			let cc = $scope.payment.card;
			cc.cardType = $scope.myForm.ccNumber.$ccType;
			if (cc.ccExpirationYear.length === 2) {
				cc.ccExpirationYear = parseInt(cc.ccExpirationYear) + 2000;
			}
			try {
				await api.updatePaymentInfo($scope.payment);
				toastr.success('Your payment info has been updated', 'Success');
				$location.path('/'); // FIX! take to myAccount page, once one exists -ssb
			} catch (e) {
				console.error('Error during submitPaymentInfo', e);
			}
		};

		$scope.toggle = function (item) {
			item.shouldUpdate = !item.shouldUpdate;
			window.event.stopPropagation();
			$scope.noneChecked = $scope.payment.candidates.filter(function (o) {
				return o.shouldUpdate;
			}).length === 0;
		};

	});
