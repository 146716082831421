'use strict';

/**
 * Validates checksum on ACH routing numbers
 * @ngdoc directive
 * @name 360StoreAngularApp.directive:stRoutingNumber
 * @description
 * # stRoutingNumber
 */
angular.module('360StoreAngularApp')
	.directive('stRoutingNumber', function () {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ngModel) {
				function validateRouting(routingNumber) {
					if (!routingNumber || !/^\d{9}$/.test(routingNumber)) {
						return false;
					}

					let sum = 0;
					for (let i = 0; i < 9; i++) {
						let digit = parseInt(routingNumber.charAt(i));
						if (i % 3 === 0) {
							sum += digit * 3;
						} else if (i % 3 === 1) {
							sum += digit * 7;
						} else {
							sum += digit;
						}
					}
					return sum % 10 === 0;
				}

				ngModel.$validators.routingNumber = validateRouting;

				element.bind('input', function () {
					let value = this.value.replace(/[^\d]/g, '');
					if (value !== this.value) {
						ngModel.$setViewValue(value);
						ngModel.$render();
					}
				});
			}
		};
	});
