'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:MirrorsyncCtrl
 * @description
 * # MirrorsyncCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.directive('newConfigTypeOption', function () {
		return {
			restrict: 'C',
			scope: {
				type: '=type',
				selected: '=selected'
			},
			template: '<div class="panel" style="width:auto;" ng-class="{\'panel-primary\':selected, \'panel-info\':!selected}">' +
				'<div class="panel-heading">' +
				'<h3 class="panel-title">' +
				'<span class="glyphicon glyphicon-check" ng-show="selected"></span>' +
				'<span class="glyphicon glyphicon-unchecked" ng-hide="selected"></span>' +
				' {{type.name}}<span ng-show="false">: ${{type.price}}</span>' +
				'</h3>' +
				'</div>' +
				'' +
				'<div class="panel-body">' +
				'{{type.description}}' +
				'</div>' +
				'</div>'
		};
	})
	.directive('oldPriceDisplay', function () {
		return {
			restrict: 'C',
			template: '<span>' +
				'<span ng-show="orderType==\'new\'"><span class="glyphicon glyphicon-star"></span> Free!</span>' +
				'<span ng-show="orderType==\'upgrade\'"><span class="glyphicon glyphicon-thumbs-up"></span> Upgraded!</span>' +
				'<span ng-show="orderType==\'add\'"><span class="glyphicon glyphicon-cloud"></span> Existing</span>' +
				'</span>'
		};
	})
	.directive('bundleDisplay', function () {
		return {
			restrict: 'C',
			scope: {
				bundle: '=bundle',
				bundleCountDisplay: '&'
			},
			link: function ($scope) {
				$scope.bundleCountDisplay = function (count) {
					return count === 2147483647 ? 'Unlimited' : count;
				};
			},
			template: '<span class="glyphicon glyphicon-phone"></span> ' +
				'<span ng-hide="bundle.count>=60"> </span>' +
				// '<span ng-show="bundle.count>=60">Unlimited</span> ' +
				'FileMaker Pro/Go Device<span ng-show="bundle.count!=1">s</span> ({{bundleCountDisplay(bundle.count)}})'
		};
	})
	.controller('MirrorSyncCtrl', function ($scope, $location, $filter, $anchorScroll, $uibModal, api, session, /**MirrorSyncPricing*/support) {
		/*if (false) {
			// noinspection UnreachableCodeJS
			support = {
				'devicePricingTiers': [{'count': 1, 'price': 95}, {'count': 5, 'price': 80}, {'count': 10, 'price': 70}, {'count': 40, 'price': 60}, {'count': 60, 'price': 50}, {'count': 100, 'price': 40}],
				'classificationOptions': [{'code': 'fmServer', 'name': 'FileMaker Server', 'price': 800, 'hubCapable': true, 'description': 'FileMaker Server installation, with XML publishing enabled.'}, {
					'code': 'jdbc',
					'name': 'Non-FileMaker SQL Database',
					'price': 1000,
					'hubCapable': true,
					'description': 'Oracle, MySQL, MS SQL Server, or other JDBC-compatible database.'
				}, {'code': 'fmClient', 'name': 'FileMaker Pro/Go', 'price': 800, 'hubCapable': false, 'description': 'A FileMaker client on a laptop/iPhone/iPad running FileMaker Pro or FileMaker Go.'}, {
					'code': 'other',
					'name': 'Other',
					'hubCapable': false,
					'description': 'If it has an API, we can sync it!'
				}],
				'unlimitedPrice': 10000,
				'pricePerFmServerToClientConfig': 200
			};
		}*/
		$scope.tmp = {
			upgradeLicenseKey: localStorage.licenseKey,
			upgradeRegisteredTo: localStorage.registeredTo
		};
		$scope.configTypes = support.classificationOptions;
		$scope.devicePricingTiers = support.devicePricingTiers;
		$scope.orderType = null; // 'new', 'upgrade', 'add'
		$scope.tmpOrderType = null; // 'new', 'upgrade', 'add'
		$scope.upgradeError = null;
		$scope.tmpNewConfig = {count: 1, hub: null, spoke: null, unlimited: false};
		$scope.custom = {company: '', name: '', phone: '', email: '', type: ''};
		$scope.newestVersion = support.newestVersion;
		$scope.versionName = support.versionName;
		$scope.version = support.majorVersion || $scope.newestVersion;

		$scope.oldConfigs =
			[
				{type1: $scope.configTypes[0], type2: $scope.configTypes[2], count: 1}
			];
		$scope.newConfigs =
			[];

		$scope.oldBundles =
			[
				$scope.devicePricingTiers[0]
			];
		$scope.newDeviceModel = {count: 1, price: support.devicePricingTiers[0].price};
		$scope.newBundles =
			[];

		/** Filter function */
		$scope.countGreaterThanZero = function (bundle) {
			return bundle.count > 0;
		};

		/**
		 * config types:
		 * 0: FM Server
		 * 1: SQL DB
		 * 2: FM Pro/Go
		 * 3: Other
		 * @param bundleIndex index of device count to add: 1, 5, 10, 40, 60
		 * @param qty how many of the configurations
		 * @param hub configType index for hub.
		 * @param spoke configType index for spoke
		 */
		$scope.orderPackage = function (bundleIndex, qty, hub, spoke) {
			$scope.newConfigs = [];
			$scope.newBundles = [];
			if (qty) {
				$scope.tmpNewConfig.count = qty;
				$scope.tmpNewConfig.hub = $scope.configTypes[hub];
				$scope.tmpNewConfig.spoke = $scope.configTypes[spoke];
				$scope.applyNewConfiguration();
			}
			if (bundleIndex !== null) {
				const tier = $scope.devicePricingTiers[bundleIndex];
				$scope.newDeviceModel.count = tier.count;
				$scope.applyNewDevices();
			}
			const oldId = $location.hash();
			$location.hash('summary');
			$anchorScroll();
			$location.hash(oldId);
		};

		$scope.getNewLicense = function () {
			$scope.orderType = 'new';
			$scope.version = $scope.newestVersion;
		};

		/**
		 * @param tmpOrderType
		 */
		$scope.upgradeLicense = function (tmpOrderType) {
			$scope.tmpOrderType = tmpOrderType;
			$scope.version = $scope.newestVersion;
			$scope.tmp.upgradeModal = $uibModal.open({
				templateUrl: 'upgradeModal',
				scope: $scope
			});
		};

		$scope.showFreeMessage = function () {
			toastr.success('This configuration is free! Just check out and start syncing.');
		};

		$scope.configTotal = function () {
			return $scope.newConfigs.reduce(function (sum, each) {
				return sum + each.price;
			}, 0);
		};

		$scope.deviceTotal = function () {
			return $scope.newBundles.reduce(function (sum, each) {
				return sum + each.price;
			}, 0);
		};

		$scope.deviceCountAdded = function () {
			return $scope.newBundles.reduce(function (sum, each) {
				return sum + each.count;
			}, 0);
		};

		$scope.grandTotal = function () {
			let result = 0;
			result += $scope.configTotal();
			result += $scope.deviceTotal();
			if ($scope.upgrade) {
				result += $scope.upgrade.price;
			}
			return result;
		};

		$scope.removeConfiguration = function (newConfigIndex) {
			if (window.confirm('Are you sure you want to remove this configuration?')) {
				$scope.newConfigs.splice(newConfigIndex, 1);
			}
		};

		$scope.removeBundle = function (bundleIndex) {
			if (window.confirm('Are you sure you want to remove this device bundle?')) {
				$scope.newBundles.splice(bundleIndex, 1);
			}
		};

		$scope.chooseNewConfigHub = function (type) {
			$scope.tmpNewConfig.hub = type;
		};
		$scope.chooseNewConfigSpoke = function (type) {
			$scope.tmpNewConfig.spoke = type;
			$scope.didChangeConfigurationCount();
		};

		$scope.addDevicesModal = function () {
			$scope.tmp.addDevicesModal = $uibModal.open(
				{
					templateUrl: 'addDevicesModal',
					scope: $scope
				}
			);
		};

		$scope.addConfigModal = function () {
			$scope.tmp.addConfigModal = $uibModal.open({
					templateUrl: 'addConfigModal',
				size:'md',
					scope: $scope
				}
			);
		};

		$scope.newDevicePrice = function (addedDeviceCount) {
			let result = 0;
			const alreadyOwnedCount = $scope.oldDeviceCount();
			for (let i = 0; i < support.devicePricingTiers.length; i++) {
				const eachTier = support.devicePricingTiers[i];
				if (eachTier.count <= addedDeviceCount + alreadyOwnedCount) {
					result = addedDeviceCount * eachTier.price;
				}
			}
			return result;
		};
		$scope.refreshNewDevicePrice = function () {
			$scope.newDeviceModel.price = $scope.newDevicePrice($scope.newDeviceModel.count);
		};
		$scope.applyNewDevices = function () {
			$scope.refreshNewDevicePrice();
			let newBundle;
			// var newDeviceCount = $scope.newDeviceModel.count;
			for (let i = 0; i < $scope.newBundles.length; i++) {
				const o = $scope.newBundles[i];
				if (o.isNew) {
					newBundle = o;
					break;
				}
			}
			if (!newBundle) {
				$scope.newBundles.push(newBundle = {isNew: true});
			}
			newBundle.count = $scope.newDeviceModel.count;
			newBundle.price = $scope.newDeviceModel.price;
			if ($scope.tmp.addDevicesModal) {
				$scope.tmp.addDevicesModal.close();
			}
			// $('#addDevicesModal').modal('hide');
		};

		$scope.totalConfigCount = function () {
			let i;
			let result = 0;
			for (i = 0; i < $scope.oldConfigs.length; i++) {
				const obj = $scope.oldConfigs[i];
				result += obj.count;
			}
			for (i = 0; i < $scope.newConfigs.length; i++) {
				const obj1 = $scope.newConfigs[i];
				result += obj1.count;
			}
			return result;
		};

		/**
		 * Switch to unlimited if they chose FMPro as the spoke and > 5 devices
		 */
		$scope.didChangeConfigurationCount = () => {
			if ($scope.tmpNewConfig.count > 4 && !$scope.tmpNewConfig.unlimited && $scope.tmpNewConfig.hub?.code==='fmServer' && $scope.tmpNewConfig.spoke?.code==='fmClient') {
				// switch to unlimited
				$scope.tmpNewConfig.unlimited = true;
				// $scope.tmpNewConfig.count = undefined;
			} else if (!$scope.tmpNewConfig.unlimited && !$scope.tmpNewConfig.count) {
				$scope.tmpNewConfig.count = 1;
			}
		};

		$scope.totalDeviceCount = function () {
			const sum = $scope.oldDeviceCount() + $scope.addedDeviceCount();
			return sum >= 2147483647 ? 'Unlimited' : sum + ' Total';
		};

		$scope.bundleCountDisplay = function (count) {
			return count >= 2147483647 ? 'Unlimited' : count;
		};

		$scope.newDeviceCount = 0;

		$scope.addedDeviceCount = function () {
			let result = 0;
			for (let i = 0; i < $scope.newBundles.length; i++) {
				const obj = $scope.newBundles[i];
				result += obj.count;
			}
			return result;
		};

		$scope.showingCustomForm = function () {
			return $scope.tmpNewConfig.spoke && $scope.tmpNewConfig.spoke.code === 'other';
		};

		$scope.cancelNewConfigurationDialog = function () {
			if ($scope.showingCustomForm()) {
				$scope.tmpNewConfig.spoke = null;
			} else {
				$scope.$dismiss();
			}
		};

		$scope.applyNewConfiguration = function () {
			if ($scope.tmpNewConfig.hub && $scope.tmpNewConfig.spoke) {
				let newConfig = {
					count: $scope.tmpNewConfig.count,
					unlimited: $scope.tmpNewConfig.unlimited && $scope.tmpNewConfig.hub.code === 'fmServer' && $scope.tmpNewConfig.spoke.code === 'fmClient',
					type1: $scope.tmpNewConfig.hub,
					type2: $scope.tmpNewConfig.spoke,
					price: null // will be calculated in $watch
				};
				if (!newConfig.count && !newConfig.unlimited) {
					return toastr.warning('Please choose a device count');
				}
				$scope.newConfigs.push(newConfig);
				$scope.tmpNewConfig.hub = $scope.tmpNewConfig.spoke = null;
				$scope.tmpNewConfig.count = 1;
				$scope.tmpNewConfig.unlimited = false;
				if ($scope.tmp.addConfigModal) {
					$scope.tmp.addConfigModal.close();
				}
			} else {
				toastr.warning('Please choose a configuration type for both sides.');
				return false;
			}
		};

		$scope.submitCustomSyncForm = function (customForm) {
			if (!customForm.$valid) {
				toastr.warning('Please correct invalid field values and try again.');
				return false;
			}
			if (!$scope.custom.email) {
				toastr.warning('Please enter your email address');
				return false;
			}
			api.postMirrorSyncCustomForm($scope.custom)
				.then(function (response) {
					if (response.error) {
						return toastr.error(response.error);
					} else {
						$scope.custom = {contactMe: true};
						customForm.$setPristine();
						if ($scope.tmp.addConfigModal) {
							$scope.tmp.addConfigModal.close();
						}
						toastr.success('We will contact you shortly to discuss details.', 'Your request has been submitted!');
					}
				});
		};

		const UNLIMITED_CONFIG_THRESHOLD_FMS_TO_FMS = 5;

		function calculatePrice(config) {
			const h = config.type1.code, s = config.type2.code;

			if (h === 'fmServer' && s === 'fmClient') {
				return config.unlimited ? support.unlimitedPrice : (config.count * support.pricePerFmServerToClientConfig);
			} else {
				if (config.type1.price === null || config.type2.price === null) {
					return null;
				}
				let configCountToCharge = config.count;
				if ( config.type1.code === 'fmServer' && config.type2.code === 'fmServer') {
					configCountToCharge = Math.min(UNLIMITED_CONFIG_THRESHOLD_FMS_TO_FMS, configCountToCharge);
				}
				return configCountToCharge * (config.type1.price + config.type2.price);
			}
		}


		$scope.$watch('newConfigs', function () {
			for (let i = 0; i < $scope.newConfigs.length; i++) {
				const eachConfig = $scope.newConfigs[i];
				eachConfig.price = calculatePrice(eachConfig);
			}
		}, true);

		$scope.pricePer = function () {
			if (!$scope.tmpNewConfig.hub || !$scope.tmpNewConfig.spoke) {
				return null;
			}
			return calculatePrice({count: 1, type1: $scope.tmpNewConfig.hub, type2: $scope.tmpNewConfig.spoke});
		};

		$scope.upgradeDialogTitle = function () {
			if ($scope.tmpOrderType === 'upgrade') {
				return 'Upgrade to MirrorSync ' + $scope.versionName + '!';
			} else if ($scope.tmpOrderType === 'renew') {
				return 'Renew your existing MirrorSync ' + $scope.versionName + ' license';
			} else {
				return 'Increase License Limits';
			}
		};

		$scope.lookupLicense = function () {
			api.getLicense($scope.tmp.upgradeLicenseKey, $scope.tmp.upgradeRegisteredTo, 57, Math.min(3, $scope.version))
				.then(licenseServerQueryCallback);
		};

		$scope.oldDeviceCount = function () {
			let result = 0;
			for (let i = 0; i < $scope.oldBundles.length; i++) {
				const obj = $scope.oldBundles[i];
				result += obj.count;
			}
			return result;
		};

		function gotoRenewPage(license) {
			$location.path('/renew')
				.search('licenseKey', license.licenseKey)
				.search('registeredTo', $scope.tmp.upgradeRegisteredTo);
		}

		$scope.gotoRenewalPage = function (license) {
			gotoRenewPage(license);
		};

		/**
		 * @param license {LicenseInfo|ErrorResponse}
		 */
		function licenseServerQueryCallback(license) {
			if (license.error) {
				$scope.upgradeError = license.error;
				return;
			}
			localStorage.setItem('licenseKey', license.licenseKey);
			localStorage.setItem('registeredTo', license.registeredTo);

			if (license.expirationDate < new Date().getTime()) {
				$scope.upgradeError = 'This license has expired! Please contact us to resolve this issue.';
				return;
			}
			// done with validation, clear errors
			$scope.upgradeError = null;
			// nuke new configs and bundles. If they enter a license key twice, we don't want to add duplicate upgrade items. Easy enough for user to re-add stuff.
			$scope.oldConfigs = [];
			$scope.newConfigs = [];
			$scope.oldBundles = [];
			$scope.newBundles = [];

			if ($scope.tmpOrderType === 'renew') {
				// they did this via the "renew" dialog, redirect them to the license renewal page
				gotoRenewPage(license);
				return;
			}

			if ($scope.tmpOrderType === 'upgrade' && license.version >= 3) {
				if (license.maxReleaseDate < new Date().getTime() - 30 * 86400000) {
					$scope.upgradeError = 'This license cannot be renewed/upgraded, it expired on ' + $filter('date')(license.maxReleaseDate);
					return;
				}
				$scope.renewableLicense = license;
			}

			if (license.variant === 2 || license.variant === 5 || license.hasCapabilities) { // enterprise license, or a previously-activated portfolio license
				$scope.oldBundles.push({count: license.capabilities.deviceCount, price: 0, upgrade: true});
				for (let i = 0; i < license.capabilities.configurations.length; i++) {
					const eachCapabilityConfig = license.capabilities.configurations[i];
					try {
						$scope.oldConfigs.push({
							count: eachCapabilityConfig.count,
							type1: classificationWithCode(eachCapabilityConfig.databases[0]),
							type2: classificationWithCode(eachCapabilityConfig.databases[eachCapabilityConfig.databases.length-1]),
							price: 0,
							upgrade: true
						});
					} catch (err) {
						console.log('Warning: could not process configuration from license: ' + err + ', this will not be added');
					}
				}
				$scope.refreshNewDevicePrice();
				if (license.version === $scope.version - 1 && license.productCode !== 35) { // this is an upgrade, not a portfolio license
					$scope.upgrade = {price: license.upgradeCost, description: 'Upgrade from version ' + license.version, licenseKey: license.licenseKey, registeredTo: license.registeredTo};
				} else if (license.version === $scope.version) {
					// up-to-date, no upgrade charge needed
					$scope.existingLicense = {price: 0, licenseKey: license.licenseKey, registeredTo: license.registeredTo};
				} else if (license.version > $scope.version) {
					// newer? shouldn't really happen, unless you tried to upgrade a 3 license on the 2 page, which we should disallow if pricing is different for old versions
					$scope.upgradeError = 'This license cannot be upgraded, as the version is too new.';
					return;
				} else if (license.productCode === 35 && license.maxReleaseDate < new Date().getTime()) { // this is an expired portfolio, treat as an update from v1
					//$scope.upgrade = {price: ?, description: 'Upgrade from expired Portfolio license', licenseKey: license.licenseKey, registeredTo: license.registeredTo};
					$scope.upgradeError = 'This license cannot be upgraded, as your portfolio license has expired.';
					return;
				} else if (license.productCode === 35) {
					// upgrade the portfolio license for free
					$scope.upgrade = {price: 0, description: 'Upgrade existing license', licenseKey: license.licenseKey, registeredTo: license.registeredTo};
				} else {
					$scope.upgradeError = 'This license cannot be upgraded, as the version is too old.';
					return;
				}
			} else {
				$scope.upgradeError = 'This license is for a variant (' + license.variant + ') which is not supported. Please contact us to resolve this issue.';
				return;
			}
			$scope.orderType = license.version === $scope.version ? 'add' : 'upgrade';
			$scope.tmp.upgradeModal.close();
		}

		function classificationWithCode(code) {
			for (let i = 0; i < support.classificationOptions.length; i++) {
				const obj = support.classificationOptions[i];
				if (obj.code === code) {
					return obj;
				}
			}
			throw 'Unknown code: ' + code;
		}

		$scope.checkout = function () {
			// api.addMirrorSync({targetVersion: $scope.version, newConfigs: $scope.newConfigs, newBundles: $scope.newBundles, upgrade: $scope.upgrade, existingLicense: $scope.existingLicense})
			api.createCartAction({action:'MirrorSync', mirrorSyncRequest:{targetVersion: $scope.version, newConfigs: $scope.newConfigs, newBundles: $scope.newBundles, upgrade: $scope.upgrade, existingLicense: $scope.existingLicense}})
				.then(
					function (action) {
						if (!action.lineItems.length) {
							toastr.warning('You have not added any devices / configurations to your license', 'Empty Cart');
							return;
						}
						session.clearCart(); // shouldn't have anything besides MirrorSync in the cart
						session.addCartAction(action);
						$location.path('/checkout');
					});
		};

		function getParameterByName(name) {
			// noinspection RegExpSimplifiable
			name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'), results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
		}


		function init() {
			const licenseKey = getParameterByName('licenseKey'),
				registeredTo = getParameterByName('registeredTo'),
				action = getParameterByName('action');
			if (licenseKey && registeredTo) {
				api.getLicense(licenseKey, registeredTo, 57, $scope.version).then(licenseServerQueryCallback);
			} else if (action === 'pricing') {
				$scope.orderType = 'new';
			}
		}

		init();
	});

